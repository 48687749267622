.textarea{
    width: 100%; height: 200px; font-size: 14px; line-height: 18px; border: 1px solid #dddddd; padding: 10px;
}
.checklist{
    margin: auto;
    width: 80%;
    /* border: 3px solid green; */
    padding: 10px;
}
.checklist label{
  font-weight: 100 !important;
}
.checklist label{
  padding-left: 10px;
}

.tbPolicyDet tr{
  display: flex;
 overflow-x: scroll; 
}

.tbPolicyDet thead tr td{
  width: 100px;
  /* display: flex; */
  /* flex-direction: row; */
}
.tbPolicyDet tbody tr td{
  flex-direction: row;
  width: 100px;
}